@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-display: block;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('./assets/fonts/Inter-roman.var.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-display: block;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url('./assets/fonts/Inter-italic.var.woff2') format('woff2');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  padding: 0;
  /* height: 100%; */
  /* min-height: 100%;
  overflow: hidden;
  position: relative; */
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%; */
}

#root,
#app {
  /* height: 100%; */
  min-height: 100%;

  /* position: fixed;
  overflow: hidden; */
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  -webkit-overflow-scrolling: touch;
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    /* opacity: 0; */
    transform: translate(20px, 0);
  }
  to {
    /* opacity: 1; */
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    /* opacity: 1; */
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    /* opacity: 0; */
  }
}

.slideLeft {
  animation: 0.1s slideLeft forwards linear;
}

.slideRight {
  animation: 0.1s slideRight forwards linear;
}

@keyframes slideLeft {
  from {
    /* opacity: 0; */
    transform: translate(0px, 0);
  }
  to {
    /* opacity: 1; */
    transform: translate(-20px, 0px);
  }
}

@keyframes slideRight {
  from {
    /* opacity: 1; */
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(20px, 0);
    /* opacity: 0; */
  }
}

.slideLeftEnd {
  animation: 0.1s slideLeftEnd linear;
}

.slideRightEnd {
  animation: 0.1s slideRightEnd linear;
}

@keyframes slideLeftEnd {
  from {
    /* opacity: 0; */
    transform: translate(20px, 0);
  }
  to {
    /* opacity: 1; */
    transform: translate(0px, 0px);
  }
}

@keyframes slideRightEnd {
  from {
    /* opacity: 1; */
    transform: translate(-20px, 0px);
  }
  to {
    transform: translate(0px, 0);
    /* opacity: 0; */
  }
}

.animate-spin-slower {
  animation: spin 6s linear infinite;
}
